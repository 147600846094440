import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  Grid,
  GridItem,
  Text,
  shouldForwardProp,
  Heading,
} from '@chakra-ui/react';
import {
  motion,
  useMotionValue,
  useScroll,
  AnimatePresence,
  useDragControls,
  isValidMotionProp,
} from 'framer-motion';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { SliderItem } from './SliderItem';

import FadeInAnimation from '../UX/FadeInAnimation';
import RouterLink from '../Utility/Routing/RouterLink';
import GImage from '../Utility/GImage';

function ManagementSlider({ backG }) {
  const x = useMotionValue(0);
  useEffect(() => {
    x.onChange(() => {
      console.log('Damn it');
      console.log(x.get());
    });
  }, []);

  return (
    <Box bg={backG}>
      <Container pt={24} px={0} variant={''} maxW='100%'>
        <>
          <SliderItem
            minH={{ base: '50vh', md: '30vh' }}
            as={motion.div}
            btnVariant={'solid'}
          >
            <Grid display={'grid'} templateRows='1fr' templateColumns='1fr'>
              <GridItem colSpan={1} rowSpan={1} colStart={1} rowStart={1}>
                <GImage
                  minH={{ base: '50vh', md: '30vh' }}
                  opacity={0.4}
                  src={'mood/dylan-gillis-KdeqA3aTnBY-unsplash.jpg'}
                  alt={' '}
                  w={'100%'}
                  h={'50vh'}
                  objectFit={'cover'}
                  objectPosition={'center'}
                />
              </GridItem>
              <GridItem
                zIndex={999999999999}
                p={{ base: '8', md: '12', lg: '24' }}
                colSpan={1}
                rowSpan={1}
                colStart={1}
                rowStart={1}
              >
                <FadeInAnimation
                  delay={0.5}
                  duration={0.5}
                  threshold={0}
                  initialX={0}
                  initialY={12}
                >
                  <Heading
                    size={{ base: 'xl', md: 'xl', lg: '4xl' }}
                    color={'white'}
                    lineHeight={'base'}
                  >
                    Unsere Leistungen für Sie <br />
                    und Ihr Unternehmen:
                  </Heading>
                </FadeInAnimation>
              </GridItem>
            </Grid>
          </SliderItem>
          <SliderItem as={motion.div} btnVariant={'solid'}>
            <Grid display={'grid'} templateRows='1fr' templateColumns='1fr'>
              <GridItem
                colSpan={1}
                rowSpan={1}
                colStart={1}
                rowStart={1}
              ></GridItem>
              <GridItem
                zIndex={999999999999}
                p={{ base: '8', md: '12', lg: '24' }}
                colSpan={1}
                rowSpan={1}
                colStart={1}
                rowStart={1}
              >
                <FadeInAnimation
                  delay={0.5}
                  duration={0.9}
                  threshold={0}
                  initialX={0}
                  initialY={12}
                  mb={8}
                >
                  <Heading
                    as='h2'
                    size={{ base: 'md', md: 'lg', lg: 'xl' }}
                    color={'brand.violet'}
                  >
                    Individualität und Kreativität macht uns aus.
                    <br />
                    Wir gestalten und verändern für Sie.
                  </Heading>
                </FadeInAnimation>
                <FadeInAnimation
                  delay={1}
                  duration={0.9}
                  threshold={0}
                  initialX={0}
                  initialY={12}
                >
                  <Heading
                    size={{ base: 'sm', md: 'sm', lg: 'md' }}
                    lineHeight={'base'}
                    color={'brand.gray.900'}
                  >
                    Dabei gilt unsere Leidenschaft stets den Menschen, die in
                    und für Organisationen und Unternehmen aktiv sind – ob als
                    Entscheider im Top-Management, als Führungskraft oder als
                    Mitarbeiter, ohne den unternehmerischen Fokus zu verlieren.
                    Unsere Arbeitsweise ist methodisch-wissenschaftlich
                    fundiert, aber nicht dogmatisch. Unsere Lösungen sind dabei
                    individuell auf Ihre Bedürfnisse zugeschnitten, begleitend
                    und implementierend.
                    <br />
                    <br /> Wir erwarten von Ihnen einen hohen Anspruch an uns.
                  </Heading>
                </FadeInAnimation>
              </GridItem>
            </Grid>
          </SliderItem>

          <SliderItem as={motion.div} btnVariant={'solid'}>
            <Box
              p={{ base: '4', md: '12', lg: '24' }}
              w={{ base: '100%', md: '86%' }}
            >
              <FadeInAnimation
                delay={1}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  mt={8}
                  size={{ base: 'md', md: 'md', lg: 'lg' }}
                  lineHeight={'base'}
                  color={'brand.violet'}
                >
                  Beratung der Geschäftsleitung im Umgang mit Betriebsräten,
                  Betriebsratswahl
                </Heading>
                <Heading
                  mt={8}
                  size={{ base: 'md', md: 'md', lg: 'lg' }}
                  lineHeight={'base'}
                  color={'brand.violet'}
                >
                  Planung, Steuerung und Umsetzung von Projekten
                </Heading>
                <Heading
                  display={'flex'}
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  py={4}
                  my={4}
                  borderTop={'1px solid #666'}
                >
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  Implementierung / Begleitung eines betrieblichen
                  Gesundheitsmanagements
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                delay={1}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  display={'flex'}
                  py={4}
                  my={4}
                  borderTop={'1px solid #666'}
                >
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  Durchführung / Begleitung einer Altersstrukturanalyse
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                delay={1}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  py={4}
                  display={'flex'}
                  my={4}
                  borderTop={'1px solid #666'}
                >
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  ON-Boarding Prozess
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                delay={1}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  py={4}
                  my={4}
                  display={'flex'}
                  borderTop={'1px solid #666'}
                >
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  Beratung, Planung und Steuerung von Restrukturierungsmaßnahmen
                  <br />
                  (Verhandlungen, Massenentlassungsanzeige, Interessenausgleich,
                  Sozialplan, Transfergesellschaft etc.)
                </Heading>
              </FadeInAnimation>
            </Box>
          </SliderItem>
          <SliderItem as={motion.div} btnVariant={'solid'}>
            <Box
              p={{ base: '4', md: '12', lg: '24' }}
              w={{ base: '100%', md: '86%' }}
            >
              <FadeInAnimation
                delay={1}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  mt={8}
                  size={{ base: 'md', md: 'md', lg: 'lg' }}
                  lineHeight={'base'}
                  display='flex'
                  color={'brand.violet'}
                >
                  Unterstützung der HR-Ansprechpartner und Führungskräfte
                </Heading>

                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  py={4}
                  my={4}
                  w={'100%'}
                  display={'flex'}
                  borderTop={'1px solid #666'}
                  display='flex'
                >
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  <Text>
                    Beendigungs-, Kündigungs-, Aufhebungsvertragsgespräche
                  </Text>
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                delay={1}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  display={'flex'}
                  py={4}
                  my={4}
                  borderTop={'1px solid #666'}
                  display='flex'
                >
                  {' '}
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  Rekrutierungsprozesse, Gehaltsverhandlungen,
                  Einstellungsverträge, Zielvereinbarungen{' '}
                </Heading>
              </FadeInAnimation>
            </Box>
          </SliderItem>

          <SliderItem as={motion.div} btnVariant={'solid'}>
            <Box
              p={{ base: '8', md: '12', lg: '24' }}
              w={{ base: '100%', md: '76%' }}
            >
              <FadeInAnimation
                delay={1}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  mt={8}
                  size={{ base: 'md', md: 'md', lg: 'lg' }}
                  lineHeight={'base'}
                  color={'brand.violet'}
                >
                  Management Development
                </Heading>
                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  py={4}
                  display='flex'
                  my={4}
                  borderTop={'1px solid #666'}
                >
                  {' '}
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  Konzeption, Vorbereitung und Durchführung von Maßnahmen zur
                  Entwicklung von Führungskräften
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                delay={1}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  mt={8}
                  size={{ base: 'md', md: 'md', lg: 'lg' }}
                  lineHeight={'base'}
                  color={'brand.violet'}
                >
                  Talent Management
                </Heading>
                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  py={4}
                  my={4}
                  display='flex'
                  borderTop={'1px solid #666'}
                >
                  {' '}
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  Identifikation, Rekrutierung. Entwicklung, Bindung und
                  Positionierung von Mitarbeitern mit hohem Potential{' '}
                </Heading>
              </FadeInAnimation>
            </Box>
          </SliderItem>
          <SliderItem as={motion.div} btnVariant={'solid'}>
            <Box
              p={{ base: '8', md: '12', lg: '24' }}
              w={{ base: '100%', md: '76%' }}
            >
              <FadeInAnimation
                delay={1}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  mt={8}
                  size={{ base: 'md', md: 'md', lg: 'lg' }}
                  lineHeight={'base'}
                  color={'brand.violet'}
                >
                  Personalgewinnung – Employer Branding
                </Heading>
                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  py={4}
                  my={4}
                  display='flex'
                  borderTop={'1px solid #666'}
                >
                  {' '}
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  Nachhaltige und prozesssichere Gestaltung der
                  Personalgewinnung
                </Heading>
                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  py={4}
                  my={4}
                  display='flex'
                  borderTop={'1px solid #666'}
                >
                  {' '}
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  Erarbeitung und Implementierung ganzer Recruitingprozesse{' '}
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                delay={1}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
              >
                <Heading
                  mt={8}
                  size={{ base: 'md', md: 'md', lg: 'lg' }}
                  lineHeight={'base'}
                  color={'brand.violet'}
                >
                  Retention Management und Coaching
                </Heading>
                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  py={4}
                  display='flex'
                  my={4}
                  borderTop={'1px solid #666'}
                >
                  {' '}
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  Unterstützung und Begleitung zur bewussten Selbstentwicklung
                </Heading>
                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  lineHeight={'base'}
                  color={'white'}
                  py={4}
                  display='flex'
                  my={4}
                  borderTop={'1px solid #666'}
                >
                  {' '}
                  <Text ml={{ base: 4, md: 8 }} mr={{ base: 4, md: 8 }}>
                    ·
                  </Text>
                  Identifikation und Optimierung zentraler und individueller
                  Anreize Zur Mitarbeiterbindung{' '}
                </Heading>
              </FadeInAnimation>
            </Box>
          </SliderItem>
          <SliderItem
            display='flex'
            w={'100%'}
            px={'10%'}
            as={motion.div}
            btnVariant={'solid'}
          >
            <Flex
              flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
              w={'100%'}
              h={'100%'}
            >
              <FadeInAnimation
                delay={0.5}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
                w={'100%'}
                h={'100%'}
              >
                <GImage
                  minH={{ base: '40vh', md: '50vh' }}
                  // src={'team/christinakdw-6249.jpg'}
                  src={'team/kdw-team-6674.jpg'}
                  alt={' '}
                  w={'100%'}
                  h={'100%'}
                  objectFit={'cover'}
                  objectPosition={'right'}
                />
              </FadeInAnimation>
              <Flex
                p={{ base: 8, md: 8, lg: 8 }}
                px={12}
                w={'100%'}
                h={'100%'}
                minW={'40%'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'start'}
              >
                <Heading
                  mt={4}
                  mb={4}
                  as='h3'
                  size={{ base: 'md', md: 'lg', lg: 'xl' }}
                  color={'white'}
                >
                  Wir stehen jederzeit für Sie bereit!
                </Heading>
                <RouterLink
                  mx={'auto'}
                  display={'inline-block'}
                  isExternal={'false'}
                  link={'/kontakt'}
                >
                  <Button
                    mt={2}
                    px={14}
                    pt={4}
                    pb={9}
                    fontSize='md'
                    textAlign='center'
                    variant={'solid'}
                    color='white'
                    bg='brand.violet'
                  >
                    Kontakt
                  </Button>
                </RouterLink>
              </Flex>
            </Flex>
          </SliderItem>
        </>
      </Container>
    </Box>
  );
}

export default ManagementSlider;
